.table-header {
  .title {
    display: flex;
    align-items: center;
    > h3 {
      margin-bottom: 0;
    }
  }
}

@primary-color: #6ebac4;@text-color: #1d4449;@color-error: rgba(231, 105, 95, 1);@color-success: rgba(82, 203, 103, 1);@place-holder-text: rgba(29, 68, 73, 0.32);@border-color: #e8e8e8;@border-primary-color: rgba(110, 186, 196, 0.4);@layout-bg-color: #fafafa;@padding-md: 16px;@control-padding-horizontal: @padding-md;@height-lg: 44px;