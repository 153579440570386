@import (once) url('variable');

// bootstrap
.border-top {
  border-top: 1px solid @border-color;
}
.border {
  // border-radius: 12%;
  border: 1px solid @border-color;
}
.border-bottom-dashed {
  border-bottom: 1px dashed @border-color;
}
.text-muted {
  color: fade(@text-color, 60%) !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}
.small {
  font-size: 12px;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-5 {
  margin-top: 2.5rem !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.mx-2 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-3 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-2 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.my-3 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.m-1 {
  margin: 8px !important;
}
.m-2 {
  margin: 16px !important;
}
.m-3 {
  margin: 24px !important;
}
/* Padding */
.p-1 {
  padding: 8px !important;
}
.p-2 {
  padding: 16px !important;
}
.p-3 {
  padding: 24px !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pb-2 {
  padding-bottom: 10px !important;
}
.pb-3 {
  padding-bottom: 15px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.pt-3 {
  padding-top: 15px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 5px !important;
}
.pr-2 {
  padding-right: 10px !important;
}
.pr-3 {
  padding-right: 15px !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.px-2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-3 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
/* Display */
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

/* Text color */
.text-success {
  color: #28a745 !important;
}
.text-danger {
  color: #dc3545 !important;
}
/* Text align */
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}

/* Grid */
.row {
  display: flex !important;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .col,
  .col-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fs-6 {
  font-size: 0.75rem !important;
}
.fs-5 {
  font-size: 1rem !important;
}
.fs-4 {
  font-size: 1.5rem !important;
}
.fs-3 {
  font-size: 2.25rem !important;
}
.fs-2 {
  font-size: 3rem !important;
}
.fs-1 {
  font-size: 4rem !important;
}
.text-wrap {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.rounded-pill {
  border-radius: 20px !important;
}

.rounded-small {
  border-radius: 5px !important;
}

@primary-color: #6ebac4;@text-color: #1d4449;@color-error: rgba(231, 105, 95, 1);@color-success: rgba(82, 203, 103, 1);@place-holder-text: rgba(29, 68, 73, 0.32);@border-color: #e8e8e8;@border-primary-color: rgba(110, 186, 196, 0.4);@layout-bg-color: #fafafa;@padding-md: 16px;@control-padding-horizontal: @padding-md;@height-lg: 44px;